import { trackEvent } from 'app/packs/src/services/event-tracker'
import { store } from 'store/index'
import { User } from 'store/modules/users'

export class ReportsListItemVm {
  constructor(private report: User) {}

  trackEventName = '$track_reports_page_table_link_clicked'

  get actionProgressPercent() {
    if (this.actions.length > 0) {
      return (this.completedActions.length / this.actions.length) * 100
    } else {
      return 100
    }
  }

  get actionStatus() {
    return `${this.completedActions.length}/${this.actions.length}`
  }

  get updateCounts() {
    const actionsCompletedCount = this.completedActions.length

    const feedbackReceivedCount = store.feedbackItems.forUsers([
      this.report.id,
    ]).length

    const notesCount = store.wins.notesForUsers([this.report.id]).length
    const winsReceivedCount = store.wins.forWinners([this.report.id]).length

    return {
      actionsCompletedCount,
      feedbackReceivedCount,
      notesCount,
      winsReceivedCount,
    }
  }

  trackEvent(link: string) {
    trackEvent(this.trackEventName, { link })
  }

  private get actions() {
    return store.actions.forUsers([this.report.id])
  }

  private get completedActions() {
    return this.actions.filter((action) => action.completed)
  }
}
