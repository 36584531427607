import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { ManagerUserSelect } from 'components/manager-user-select'
import { ReportsFilters } from '../reports-page-types'
import { User } from 'store/modules/users'
import * as SecondaryNav from 'components/secondary-nav'

type ReportsPageNavProps = {
  filters: ReportsFilters
  onChange: (filters: ReportsFilters) => void
  user: User
}

export const ReportsPageNav = observer((props: ReportsPageNavProps) => {
  const { filters, onChange, user } = props

  return (
    <SecondaryNav.Root>
      <SecondaryNav.Header>Reports</SecondaryNav.Header>
      <SecondaryNav.Body className="gap-4">
        <ManagerUserSelect
          defaultValue={filters.user_id}
          onChange={(value) => onChange({ user_id: value })}
          user={user}
        />
      </SecondaryNav.Body>
    </SecondaryNav.Root>
  )
})
