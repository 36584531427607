import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { CheckinStatusLabel } from 'components/checkin-status-label'
import { store } from 'store/index'
import { formatDate } from '../../utils/date-helpers'
import { Link } from 'src/design-system'

export type UserLastCheckinStatusLabelProps = {
  userId: string
}

export const UserLastCheckinStatusLabel = observer(
  (props: UserLastCheckinStatusLabelProps) => {
    const { userId } = props

    const checkin = store.checkins.lastFinalisedUserCheckin(userId)

    if (!checkin?.summaryStatus || !checkin.finalisedAt)
      return <p className="text-gray-400 mb-0">No completed Check-Ins</p>

    return (
      <Link
        href={checkin.path}
        underline={false}
        data-turbo-frame="content"
        data-turbo-action="advance"
      >
        <span className="flex gap-5 items-center w-max">
          <span className="flex flex-col">
            <p className="mb-0 font-bold whitespace-break-spaces">
              {checkin.positionName}
            </p>
            <p className="text-gray-600 mb-0">
              {formatDate(checkin.finalisedAt, { relative: true })}
            </p>
          </span>
          <CheckinStatusLabel status={checkin.summaryStatus} />
        </span>
      </Link>
    )
  }
)
