import { convertValueToUserIds } from 'components/manager-user-select/utils'
import { currentUrl } from 'app/packs/src/utils/url-helpers'
import { ReportsFilters } from './reports-page-types'
import { store } from 'store/index'
import { trackEvent } from 'app/packs/src/services/event-tracker'
import { User } from 'store/modules/users'

export class ReportsPageVm {
  constructor(private user?: User) {}

  reports(filters: ReportsFilters) {
    if (!this.user) return []

    const userIds = convertValueToUserIds(this.user, filters.user_id)
    return store.users.filtered((user) => userIds.includes(user.id))
  }

  get defaultFilters() {
    const filters: ReportsFilters = {}
    const searchParams = currentUrl().searchParams
    const userId = searchParams.get('user_id')

    if (userId) filters.user_id = userId
    return filters
  }

  applyFilters(filters: ReportsFilters) {
    trackEvent('$track_reports_page_filtered', filters)
    window.history.replaceState(window.history.state, '', this.url(filters))
  }

  private url(filters: ReportsFilters) {
    const url = new URL(window.location.origin + window.location.pathname)

    if (filters.user_id) url.searchParams.append('user_id', filters.user_id)
    return url
  }
}
