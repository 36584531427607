import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { CheckinCelebratoryModal } from 'components/checkin-celebratory-modal'
import { ReportsFilters, ReportsPageProps } from './reports-page-types'
import { ReportsList } from './reports-list'
import { ReportsPageNav } from './reports-page-nav'
import { ReportsPageVm } from './reports-page-vm'
import { useStore } from 'store/context'

export const ReportsPage = observer((props: ReportsPageProps) => {
  const { userId } = props

  const {
    actions,
    checkins,
    feedbackItems,
    users,
    userSkills,
    wins,
    positions,
  } = useStore()

  const user = users.byId(userId)

  const vm = React.useMemo(() => {
    return new ReportsPageVm(user)
  }, [user])

  const [filters, setFilters] = React.useState<ReportsFilters>(
    vm.defaultFilters
  )

  React.useEffect(() => {
    if (!user) return
    const reportIds = user.allReports.map((report) => report.id)

    actions.fetchAll({
      filter: { user_id: reportIds },
      include: ['skills', 'user'],
      page: { size: 1000 },
    })

    checkins.fetchAll({
      filter: { last_finalised: true },
      include: ['assessor', 'author', 'checkin_skills'],
      page: { size: 1000 },
    })

    feedbackItems.fetchAll({
      filter: { user_id: reportIds },
      include: ['author', 'receiver', 'skills'],
      page: { size: 1000 },
    })

    wins.fetchAll({
      filter: { winner_id: reportIds },
      include: ['skills', 'win_category', 'winners'],
      page: { size: 1000 },
    })
  }, [actions, checkins, feedbackItems, user, userSkills, wins, positions])

  const loading = checkins.loading

  const activityLoading = React.useMemo(() => {
    return [
      actions.loading,
      feedbackItems.loading,
      users.loading,
      wins.loading,
    ].some(Boolean)
  }, [actions.loading, feedbackItems.loading, users.loading, wins.loading])

  const onChange = (filters: ReportsFilters) => {
    setFilters(filters)
    vm.applyFilters(filters)
  }

  if (!user) return null

  return (
    <>
      <ReportsPageNav filters={filters} onChange={onChange} user={user} />
      <div className="mt-16">
        <ReportsList
          loading={loading}
          activityLoading={activityLoading}
          reports={vm.reports(filters)}
        />
      </div>
    </>
  )
})
