import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { ReportsListItem } from './reports-list-item'
import { User } from 'store/modules/users'

export type ReportsListProps = {
  reports: User[]
  loading?: boolean
  activityLoading?: boolean
}

export const ReportsList = observer((props: ReportsListProps) => {
  const { reports, loading, activityLoading } = props

  return (
    <div className="w-full max-w-full overflow-x-auto">
      <table className="table-auto min-w-full">
        <thead>
          <tr className="text-sm font-bold">
            <th className="px-4 md:px-8">Report</th>
            <th className="pr-4 md:pr-8">Last checked in</th>
            <th className="pr-4 md:pr-8">Open Check-in</th>
            <th className="pr-4 md:pr-8">Updates</th>
            <th className="pl-1.5 pr-4 md:pr-8">Actions</th>
          </tr>
        </thead>
        <tbody>
          {reports.map((report) => (
            <ReportsListItem
              key={report.id}
              report={report}
              loading={loading}
              activityLoading={activityLoading}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
})
