import { AvatarButton, Link } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import cn from 'classnames'
import { CheckinProgressBar } from 'components/checkin-progress-bar'
import { CircleProgressBar } from 'components/circle-progress-bar'
import { ReportsListItemVm } from './reports-list-item-vm'
import { StartCheckinButton } from '../../../components/start-checkin-button'
import { stateTooltipContent } from 'app/packs/src/utils/user-helpers'
import { store } from 'store/index'
import { UpdateDonut } from 'components/update-donut'
import { User } from 'store/modules/users'
import { UserLastCheckinStatusLabel } from 'components/user-last-checkin-status-label'
import { WithAnalytics } from 'components/with-analytics'
import { SkeletonCell } from 'components/table/skeleton'

export type ReportsListItemProps = {
  report: User
  loading?: boolean
  activityLoading?: boolean
}

export const ReportsListItem = observer((props: ReportsListItemProps) => {
  const { report, loading, activityLoading } = props

  const checkin = store.checkins.lastUserCheckin(report.id)

  const vm = React.useMemo(() => {
    return new ReportsListItemVm(report)
  }, [report])

  return (
    <tr key={report.id}>
      <Cell className="w-[250px] truncate">
        <div className="flex gap-2 items-center justify-between px-4 md:px-8">
          <div className="w-8">
            <AvatarButton
              alt={report.fullName}
              className="flex-shrink-0"
              fallbackText={report.initials}
              href={`/users/${report.slug}`}
              isInactive={report.state !== 'active'}
              size="sm"
              src={report.avatarUrl}
              tooltipContent={stateTooltipContent(
                report.fullName,
                report.state
              )}
            />
          </div>
          <Link
            className="flex gap-2 items-center w-full"
            href={`/users/${report.slug}`}
            onClick={() => vm.trackEvent('profile')}
            underline={false}
            data-turbo-frame="content"
            data-turbo-action="advance"
          >
            <div className="truncate">
              <div className="font-bold text-base text-gray-900 truncate">
                {report.fullName}
              </div>
              {report.position && (
                <div className="text-sm text-gray-600 truncate">
                  {report.position.name}
                </div>
              )}
            </div>
          </Link>
        </div>
      </Cell>
      <Cell className="w-[460px] pr-4 md:pr-8">
        {loading ? (
          <SkeletonCell />
        ) : (
          <UserLastCheckinStatusLabel userId={report.id} />
        )}
      </Cell>
      <Cell className="w-[230px] pr-4 md:pr-8">
        {loading ? (
          <SkeletonCell />
        ) : checkin && !checkin.isFinalised ? (
          <Link
            className="p-0"
            href={`${checkin.path}?ref=reports`}
            onClick={() => vm.trackEvent('checkin')}
            underline={false}
            data-turbo-frame="content"
            data-turbo-action="advance"
          >
            <CheckinProgressBar checkin={checkin} minimal />
          </Link>
        ) : (
          <WithAnalytics
            event={vm.trackEventName}
            params={{ link: 'new_checkin' }}
          >
            <StartCheckinButton
              variant="outline"
              source="reports-page"
              user={report}
            />
          </WithAnalytics>
        )}
      </Cell>
      <Cell>
        {activityLoading ? (
          <SkeletonCell />
        ) : (
          <div className="flex gap-1 items-center text-xs py-5 pr-4 md:pr-8 h-full">
            <UpdateDonut
              includedUpdateTypes={[
                'actions',
                'feedback_received',
                'notes',
                'wins',
              ]}
              updateCounts={vm.updateCounts}
            />
          </div>
        )}
      </Cell>
      <Cell>
        {activityLoading ? (
          <SkeletonCell />
        ) : (
          <div className="flex gap-1 items-center text-xs py-5 pr-4 md:pr-8 h-full">
            <CircleProgressBar
              progressPercent={vm.actionProgressPercent}
              size="md"
            />
            <div className="text-gray-900">{vm.actionStatus}</div>
          </div>
        )}
      </Cell>
    </tr>
  )
})

type CellProps = {
  className?: string
  children: React.ReactNode
}

const Cell = (props: CellProps) => {
  const { className, children } = props
  return (
    <td
      className={cn(
        'p-0 border-0 border-b border-solid border-gray-100 h-[85px]',
        className
      )}
    >
      {children}
    </td>
  )
}
